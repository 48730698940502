import { allowedNSFWCreationIdsAtom, creationInputAtom } from '@/atoms'
import Button from '@/components/button'
import { Creation, CreationModeEnum, GalleryItemSource } from '@/types'
import { cls } from '@/utils'
import IconImage2Video from '@haiper/icons-svg/icons/outline/multi-media.svg'
import IconNSFW from '@haiper/icons-svg/icons/outline/error.svg'
import IconDownload from '@haiper/icons-svg/icons/outline/download.svg'
import IconFullscreen from '@haiper/icons-svg/icons/outline/fullscreen.svg'
import IconBlock from '@haiper/icons-svg/icons/outline/block.svg'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useAmplitude from '@/hooks/useAmplitude'
import Tooltip from '@/components/tooltip'
import ScreenImage from '@/components/screen-image'
import { Modal } from '@/components/modal'
import { useRouter } from 'next/navigation'

interface MainCardProps {
  creation: Creation
  source: GalleryItemSource
  onClick: () => void
  blurBg?: boolean
}

const MainCard = ({ creation, source, onClick, blurBg }: MainCardProps) => {
  const { track } = useAmplitude()
  const [imageData, setImageData] = useState(creation.outputs || [])
  const [screenOpen, setScreenOpen] = useState(false)
  const setCreationInput = useSetAtom(creationInputAtom)
  const [allowedNSFWCreationIds, setAllowedNSFWCreationIds] = useAtom(
    allowedNSFWCreationIdsAtom,
  )
  const nsfw = creation.is_nsfw
  const creationId = creation.creation_id
  const illegal = creation.is_illegal
  const showNSFW = useMemo(() => {
    return !!nsfw && !allowedNSFWCreationIds.includes(creationId) && !illegal // don't show NSFW mask if illegal
  }, [allowedNSFWCreationIds, nsfw, illegal, creationId])
  const router = useRouter()

  const openText2Image = (item: { media_url: string; id?: string }) => {
    track('click:creations:image:text2image', {
      creation_id: creation.creation_id,
      image_id: item.id || '',
    })
    setCreationInput((prev) => ({
      ...prev,
      mode: CreationModeEnum.AnimateHD,
      creation: {
        ...creation,
        settings: { ...creation.settings, resolution: 720, duration: 4 },
      },
      expanded: true,
      focusing: true,
      img: item.media_url,
    }))

    if (source !== 'creations' && source !== 'explore') {
      router.push('/creations')
    }
  }

  const downloadImage = async (item: { media_url: string; id?: string }) => {
    track('click:creation:image:download', {
      image_id: item.id,
      image_url: item.media_url,
    })
    const response = await fetch(item.media_url)
    const blob = await response.blob()
    const urlBlob = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = urlBlob
    a.download = 'image.png'
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(urlBlob)
    document.body.removeChild(a)
  }

  const openScreen = (item: { media_url: string; id?: string }) => {
    track('click:creations:image:show-screen', {
      creation_id: creation.creation_id,
      image_id: item.id || '',
    })
    setScreenOpen(true)
  }

  const handleClick = (item: { media_url: string; id?: string }) => {
    track('click:creations:image:show-screen', {
      creation_id: creation.creation_id,
      image_id: item.id || '',
    })
    if (source === 'profile') {
      onClick?.()
    } else if (source === 'creations' || source === 'collection') {
      track('click:creations:image:show-screen', {
        creation_id: creation.creation_id,
        image_id: item.id || '',
      })
      setScreenOpen(true)
    }
  }

  useEffect(() => {
    setImageData(creation.outputs || [])
  }, [creation.outputs])

  const hideNSFWMask = useCallback(
    (e: any) => {
      e?.stopPropagation?.()
      e?.preventDefault?.()

      setAllowedNSFWCreationIds((old) => {
        if (old.includes(creationId)) {
          return old
        }
        return [...old, creationId]
      })
    },
    [setAllowedNSFWCreationIds, creationId],
  )

  const renderMask = () => {
    if (showNSFW) {
      return (
        <div
          className='absolute inset-0 flex justify-center items-center bg-surface text-text backdrop-blur-[50px] rounded-[6px] pointer-events-none'
          aria-label='nsfw mask'
        >
          <div
            className='flex flex-col gap-2 md:gap-4 items-center justify-between'
            aria-label='nsfw-mask-inner'
          >
            <IconNSFW alt='eye-slash' className='size-12 text-icon' />
            <div className='text-body-md tracking-15'>
              These images may contain explicit content
            </div>
            <Button
              variant='transparent'
              className='rounded-md border border-solid border-border pointer-events-auto text-body-md h-9 md:h-10'
              onClick={hideNSFWMask}
            >
              View images
            </Button>
          </div>
        </div>
      )
    }
    if (illegal) {
      return (
        <div
          className='absolute inset-0 flex justify-center items-center bg-transparent text-text backdrop-blur-[50px] rounded-[6px] pointer-events-none p-10'
          aria-label='illegal mask'
        >
          <div
            className='flex flex-col gap-4 @md:gap-6 items-center justify-between'
            aria-label='nsfw-mask-inner'
          >
            <IconBlock alt='eye-slash' className='size-12' />
            <div className='flex flex-col gap-1 items-center'>
              <div className='font-medium text-body-md'>
                Haiper doesn&apos;t allow content that encourages harmful or
                illegal activities
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  if (source === 'profile') {
    return (
      <div
        className={cls(
          'select-none size-full relative rounded-sm sm:rounded-md overflow-hidden aspect-video bg-transparent',
          creation?.is_illegal ? 'cursor-default' : 'cursor-pointer',
        )}
        onClick={() => handleClick({ media_url: creation.output_url || '' })}
      >
        <div className='relative group overflow-hidden size-full'>
          <div
            className={cls(
              'absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md',
              blurBg ? '' : 'bg-surface-base',
            )}
            style={
              blurBg
                ? {
                    backgroundImage: `url(${creation.output_url})`,
                  }
                : undefined
            }
          >
          </div>
          <img
            className={cls(
              'object-scale-down size-full m-auto absolute',
              blurBg ? '' : 'bg-surface-base',
            )}
            src={creation.output_url}
            alt=''
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={cls(
        'select-none size-full relative grid grid-cols-2 grid-rows-2 gap-[2px] rounded-sm sm:rounded-md overflow-hidden aspect-video bg-transparent',
        creation?.is_illegal ? 'cursor-default' : 'cursor-pointer',
      )}
    >
      {imageData.map((item, index) => (
        <div key={index} className='relative group overflow-hidden'>
          <div
            className='absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md'
            style={
              blurBg
                ? {
                    backgroundImage: `url(${item.media_url})`,
                  }
                : undefined
            }
            onClick={() => handleClick(item)}
          >
          </div>
          <img
            className={cls(
              'object-scale-down size-full m-auto absolute',
              blurBg ? '' : 'bg-surface-base',
            )}
            src={item.media_url}
            alt=''
            onClick={() => handleClick(item)}
          />
          <div className='hidden group-hover:flex bg-gray-900-opacity-80 rounded-full h-8 absolute bottom-2 items-center left-[50%] translate-x-[-50%] px-3 gap-2'>
            <Tooltip
              trigger={
                <Button
                  className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                  onClick={() => openText2Image(item)}
                >
                  <IconImage2Video className='size-4 text-icon-on-color' />
                </Button>
              }
            >
              <div className='text-text-on-color'>Image to Video</div>
            </Tooltip>
            <Tooltip
              trigger={
                <Button
                  className='mt-1 h-6 w-6 p-0 rounded-sm bg-transparent hover:bg-gray-700 active:bg-gray-700'
                  onClick={() => downloadImage(item)}
                >
                  <IconDownload className='size-4 text-icon-on-color' />
                </Button>
              }
            >
              <div className='text-text-on-color'>Download</div>
            </Tooltip>
          </div>
          <Button
            className='hidden group-hover:flex h-8 w-8 p-0 rounded-full bg-gray-900-opacity-80 absolute bottom-2 right-2 hover:bg-gray-700 active:bg-gray-700'
            onClick={() => openScreen(item)}
          >
            <IconFullscreen
              alt='toggle fullscreen'
              className='size-4 text-icon-on-color'
            />
          </Button>
        </div>
      ))}
      {renderMask()}
      <Modal
        className='p-4 md:p-10'
        open={screenOpen}
        onOpenChange={(open) => setScreenOpen(open)}
      >
        <ScreenImage
          creation={creation}
          onCloseModal={() => setScreenOpen(false)}
        />
      </Modal>
    </div>
  )
}

export default MainCard
