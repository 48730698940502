import Loading from '@/components/loading'
import RadialProgress from '@/components/radial-progress'
import UpgradeButton from '@/components/upgrade-button'
import useActivePlan from '@/hooks/useActivePlan'
import { Creation, GalleryItemSource, JobStatus } from '@/types'
import { cls } from '@/utils'

interface IProps {
  creation: Creation
  source: GalleryItemSource
  jobStatus: JobStatus | null
  showMode: 'mobile' | 'grid' | 'single'
}

const Pending = (props: IProps) => {
  const { jobStatus, showMode, creation } = props
  const { data: activePlan } = useActivePlan()
  const isFreePlan = Boolean(activePlan?.is_free || !activePlan)

  return (
    <div className='size-full relative border border-solid border-border rounded-lg flex justify-center items-center p-6 aspect-video'>
      <div className='w-60 min-w-60 md:w-105 h-45'>
        <div
          className={cls(
            'flex justify-center items-center flex-col bg-surface backdrop-blur-[10px] size-full relative rounded-lg gap-2',
            showMode === 'single' ? 'gap-4' : '',
          )}
        >
          {jobStatus?.progress ? (
            <div
              className={cls('flex flex-col gap-2 justify-center items-center')}
            >
              <RadialProgress
                smooth
                size={showMode === 'single' ? 64 : 50}
                className={cls(
                  'text-heading-xs',
                  showMode === 'single' ? 'size-16' : 'size-[50px]',
                )}
                progress={Math.round((jobStatus?.progress ?? 0) * 100) || 0}
                duration={10000}
              />
              <div
                className={cls(
                  'text-text text-body-md tracking-15',
                  showMode === 'single' ? 'text-body-lg' : '',
                )}
              >
                Your {creation.output_type === 'image' ? 'image' : 'video'} is
                being generated
              </div>
            </div>
          ) : (
            <div
              className={cls(
                'flex flex-col gap-2 justify-center',
                showMode === 'single' ? 'gap-4' : '',
              )}
            >
              <Loading
                className={showMode === 'single' ? 'size-16' : 'size-[50px]'}
              />
              <div
                className={cls(
                  'text-text text-body-md tracking-15',
                  showMode === 'single' ? 'text-body-lg' : '',
                )}
              >
                Queuing for generation
              </div>
            </div>
          )}
          {isFreePlan && (
            <div
              className={cls(
                'flex flex-col items-center gap-2',
                showMode === 'single' ? 'gap-4' : '',
              )}
            >
              <div
                className={cls(
                  'text-body-sm leading-4 text-text-subdued text-center tracking-15 md:tracking-32',
                  showMode === 'single' ? 'text-body-md leading-4' : '',
                )}
              >
                Upgrade to Haiper Membership to create more tasks at once.
              </div>
              <UpgradeButton
                size={showMode === 'single' ? 'default' : 'sm'}
                source='pending-creation'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pending
