import RadialProgress from '@/components/radial-progress'
import { cls } from '@/utils'
import IconClose from '@/public/assets/error.svg'
import { Creation, GalleryItemSource, JobStatus } from '@/types'
import Button from '@/components/button'
import { MouseEventHandler } from 'react'

interface IProps {
  creation: Creation
  userId: string | null
  source: GalleryItemSource
  handleRetry: MouseEventHandler<HTMLButtonElement>
  jobStatus: JobStatus | null
}

const GenerateError = (props: IProps) => {
  const { handleRetry, jobStatus } = props

  return (
    <div
      className={cls(
        'flex justify-center items-center flex-col bg-surface w-full border border-solid border-border rounded-lg aspect-video gap-2 @md:gap-3',
      )}
    >
      <RadialProgress
        size={56}
        progress={Math.round((jobStatus?.progress ?? 0) * 100) || 0}
        text={<IconClose />}
      />
      <div className='w-full flex justify-center items-center'>
        Failed to generate
      </div>
      <Button
        variant='outline'
        className='min-w-[70px] md:min-w-[70px]'
        onClick={handleRetry}
      >
        Retry
      </Button>
    </div>
  )
}

export default GenerateError
