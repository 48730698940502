import {
  Carousel as BaseCarousel,
  CarouselProps as BaseCarouselProps,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { cls } from '@/utils'
import Empty from '@/components/empty'
import { twMerge } from 'tailwind-merge'

export type { CarouselApi }

export interface CarouselProps<T = any> extends BaseCarouselProps {
  autoPlay?: boolean
  duration?: number // the duration of the carousel, default is 20
  idField?: string // the field name of the id, default is 'id'
  className?: string
  carouselClassname?: string
  itemClassName?: string
  thumbClassName?: string
  nextButtonClassName?: string
  prevButtonClassName?: string
  startIndex?: number
  bottomIndex?: (data: T[]) => ReactNode
  dataSource?: T[] | null
  itemRenderer: (item: T, index: number) => ReactNode
  previousRenderer?: () => ReactNode
  nextRenderer?: () => ReactNode
}

export interface CarouselRef {
  api: CarouselApi | undefined
  activeIndex: number
}

function Carousel<T = any>({
  autoPlay,
  className,
  carouselClassname,
  itemClassName,
  thumbClassName,
  duration = 20,
  idField = 'id',
  dataSource,
  setApi,
  itemRenderer,
  nextButtonClassName,
  previousRenderer,
  nextRenderer,
  prevButtonClassName,
  bottomIndex,
  startIndex,
  ...props
}: CarouselProps<T>) {
  const [innerApi, setInnerApi] = useState<CarouselApi>()

  const handleSetApi = useCallback(
    (api: CarouselApi) => {
      setInnerApi(api)
      setApi?.(api)
    },
    [setApi],
  )

  const isEmpty = !(dataSource?.length && dataSource?.length > 0)

  const thumbWidth = Math.ceil(200 / (dataSource?.length || 1))
  const [activeIndex, setActiveIndex] = useState(0)

  const thumbStyle: CSSProperties = useMemo(() => {
    return {
      width: thumbWidth,
      left: thumbWidth * activeIndex,
    }
  }, [thumbWidth, activeIndex])

  useEffect(() => {
    if (innerApi) {
      if (!innerApi) {
        return
      }

      innerApi.on('select', () => {
        setActiveIndex(innerApi.selectedScrollSnap())
      })
    }
  }, [innerApi, thumbWidth])

  return (
    <div
      className={cls('flex w-full flex-col gap-4 items-center', className)}
      aria-label='contents'
    >
      <BaseCarousel
        pauseOnHover
        autoPlay={autoPlay}
        setApi={handleSetApi}
        className={twMerge('w-full relative', carouselClassname)}
        opts={{
          duration,
          align: 'center',
          startIndex,
        }}
        {...props}
      >
        <CarouselContent className='ml-0 h-full transition-none'>
          {dataSource?.length ? (
            dataSource?.map((item: T, index) => {
              return (
                <CarouselItem
                  key={[(item as any)?.[idField] ?? '', index].join()}
                  className={itemClassName}
                >
                  {itemRenderer?.(item, index)}
                </CarouselItem>
              )
            })
          ) : (
            <div className='w-full flex justify-center'>
              <Empty />
            </div>
          )}
        </CarouselContent>
        {previousRenderer ? (
          previousRenderer()
        ) : (
          <CarouselPrevious className={cls(prevButtonClassName)} />
        )}
        {nextRenderer ? (
          nextRenderer()
        ) : (
          <CarouselNext className={cls(nextButtonClassName)} />
        )}
      </BaseCarousel>
      {bottomIndex ? (
        bottomIndex(dataSource || [])
      ) : (
        <div
          className={cls(
            'relative w-50 h-1 bg-border rounded-full',
            isEmpty ? 'hidden' : '',
            thumbClassName,
          )}
          aria-label='scroll'
        >
          <div
            className={cls('absolute bg-icon-interactive h-full rounded-full')}
            style={thumbStyle}
          />
        </div>
      )}
    </div>
  )
}

export default Carousel
